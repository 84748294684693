<template>
  <nav
    class="tw-flex tw-flex-col main-nav"
    role="navigation"
    @scroll="updateScroll"
  >
    <div class="countries-list tw-flex md:tw-flex-col">
      <div class="countries-list__newitem__legend">
        <span
          v-if="hasNewCountries"
        >
          {{ newItemLegendLabel }}
        </span>
      </div>
      <country-list-item
        v-for="(sections, country) in alphaSortedCountriesList"
        :key="country"
        :class="{
          active: $parent.selectedCountry === country
        }"
        :country="country"
        :sections="sections"
        :selected-sections="$parent.selectedDivisions[country]"
        :disabled="$parent.isCountryDisabled(country)"
        :data-test="`country-list-item-${country}`"
        @click.native.stop="sections.length
          ? selectCountry(country)
          : toggleSelectAllCountry(country)"
      />
    </div>
    <p
      v-if="hasDisabledCountry"
      v-text="`* ${$t('offers.paragraphs.not_allowed_countries')}`"
      class="countries-not-available tw-text-sm tw-mb-0 tw-text-gray-800 tw-whitespace-normal tw-px-4 tw-py-2"
      data-test="not-allowed-countries"
    />

    <transition name="fade">
      <div
        v-if="isShadowLeftVisible"
        class="country-item-shadow left"
      />
    </transition>
    <transition name="fade">
      <div
        v-if="isShadowRightVisible"
        class="country-item-shadow right"
      />
    </transition>
  </nav>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CountriesList from '@/assets/geo/geo.json'
  import CountryListItem from '../CountryListItem/index.vue'

  import useCountryLists from '@/composables/useCountryLists'

  export default defineComponent({
    name: 'SelectCountry',
    components: {
      CountryListItem
    },
    props: {
      direction: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        scrollPosition: 0,
        countriesList: CountriesList,
        newItemLegendLabel: this.$t('offers.labels.new_item')
      }
    },
    computed: {
      /**
       * Returns true if there is at least one disabled country in the list
       * @function hasDisabledCountry
       * @returns {boolean}
       */
      hasDisabledCountry () {
        return Object.keys(this.countriesList)
          .some(country => this.$parent.isCountryDisabled(country))
      },
      isShadowLeftVisible () {
        return this.scrollPosition >= 100
      },
      isShadowRightVisible () {
        const nav = document.querySelector('.main-nav')
        return nav ? this.scrollPosition <= (nav.scrollWidth - nav.clientWidth) - 100 : null
      },
      alphaSortedCountriesList () {
        const countries = this.countriesList
        const arr = Object.keys(countries)

        // except-arrow-function
        arr.sort((a, b) => {
          if (this.$t(a) < this.$t(b)) return -1
          if (this.$t(a) > this.$t(b)) return 1
          return 0
        })

        // eslint-disable-next-line
        return arr.reduce((r, k) => (r[k] = countries[k], r), {})
      },
      hasNewCountries () {
        const { hasNewCountry } = useCountryLists()
        return !!hasNewCountry()
      }
    },
    methods: {
      updateScroll (e) {
        const width = document.documentElement.clientWidth
        if (width <= 768) {
          this.scrollPosition = e.target.scrollLeft
        }
      },
      toggleSelectAllCountry (iso) {
        if (this.$parent.isCountryDisabled(iso)) return false

        if (this.$parent.selectedDivisions[iso] === true) {
          this.$parent.selectedDivisions[iso] = []
        } else {
          this.$parent.selectedDivisions[iso] = true
        }
      },
      selectCountry (iso) {
        if (this.$parent.isCountryDisabled(iso)) return false

        this.$parent.selectedCountry = iso
      }
    }
  })
</script>

<style lang="scss" scoped>
.main-nav {
  border-right: 1px solid #F6F6F6;
  overflow: auto;
  flex: 0 0 355px;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 2px 0 10px rgba(black, 0.15);
}
.main-nav .countries-list__newitem__legend {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: none;
  height: 50px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #E9E9E9;
  transition: background-color 100ms;
}
@media (min-width: 770px) {
  .main-nav .countries-list__newitem__legend {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .main-nav .countries-list__newitem__legend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.main-nav .countries-list__newitem__legend span {
  position: relative;
}
@media (min-width: 1024px) {
  .main-nav .countries-list__newitem__legend span {
    margin-left: 8px;
  }
}
.main-nav .countries-list__newitem__legend span::before {
  content: '';
  position: absolute;
  top: 5px;
  left: -8px;
  background-color: #96BF31;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  z-index: 1;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .main-nav {
    min-height: $list-height;
    width: 100%;
    width: 100vw;
    flex: 0 0 auto;
    overflow: visible;
  }
  .main-nav .countries-list {
    height: $list-height;
    max-height: $list-height;
    overflow: auto;
  }
  .main-nav .countries-not-available {
    border-top: 1px solid $divider;
  }
}
.main-nav .country-item-shadow {
  position: absolute;
  height: $list-height;
  width: $list-height;
  background-color: rgba(black, 0.2);
  pointer-events: none;
}
.main-nav .country-item-shadow::after {
  position: absolute;
  content: ' ';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent $secondary;
}
.main-nav .country-item-shadow.left {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0) 100%);
}
.main-nav .country-item-shadow.left::after {
  transform: rotate(180deg);
}
.main-nav .country-item-shadow.right {
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.2) 100%);
}
</style>
