<template>
  <div>
    <div class="tw-flex tw-flex-col tw-items-center tw-text-center tw-m-auto">
      <img
        src="@/assets/img/icons/search.svg"
        alt=""
        width="80"
        height="80"
        class="tw-mb-4"
        data-test="icon"
      >
      <h2
        v-text="title"
        class="tw-text-xl tw-font-normal md:tw-w-2/3 tw-mx-auto"
        data-test="title"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'OfferUnselected',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  })
</script>
