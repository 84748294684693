var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"search-item search-container tw-relative tw-bg-white tw-cursor-pointer tw-flex tw-items-center tw-text-secondary-text hover:tw-text-secondary-text",attrs:{"to":{
    name: 'Searches',
    params: {
      uuid: _vm.search.uuid
    }
  },"title":_vm.search.name,"disabled":_vm.$wait.is(("deleting " + (_vm.search.uuid) + " search item")),"event":_vm.$wait.is(("deleting " + (_vm.search.uuid) + " search item")) ? '' : 'click'},nativeOn:{"click":function($event){return _vm.select.apply(null, arguments)}}},[_c('search-item-alert',{attrs:{"search":_vm.search,"disabled":_vm.$wait.is(("deleting " + (_vm.search.uuid) + " search item"))}}),_c('p',{staticClass:"search-container-name tw-truncate tw-m-0 tw-flex-1",domProps:{"textContent":_vm._s(_vm.search.name)}}),_c('b-dropdown',{staticClass:"ctk-dropdown tw-flex tw-items-center",attrs:{"disabled":_vm.$wait.is(("deleting " + (_vm.search.uuid) + " search item")),"variant":"link","dropdown":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"tw-flex tw-m-auto"},[_c('ui-material-icon',{attrs:{"name":"more_vert"}})],1)]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.edit(_vm.search)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('modify_search')))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.duplicate(_vm.search)}}},[_vm._v(" "+_vm._s(_vm.$t('duplicate_search'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.remove(_vm.search)}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.delete'))+" ")])],1),_c('div',{staticClass:"search-item__badge tw-text-secondary-text tw-text-xs tw-text-center tw-px-1 tw-flex-shrink-0 tw-relative",class:{
      'search-item__badge--has-dot': _vm.search.unviewed !== null && _vm.search.unviewed > 0,
      'search-item__badge--count': _vm.search.count && _vm.search.count > 0
    }},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"search-item__badge__icon tw-rounded-md",attrs:{"title":_vm.search.unviewed !== null && _vm.search.unviewed > 0
        ? _vm.$tc('offers.values.unviewed_offers', _vm.search.unviewed, {
          count: _vm.$n(_vm.search.unviewed)
        })
        : null}}),_c('span',{staticClass:"search-item__badge__content tw-relative",domProps:{"textContent":_vm._s((_vm.search.count !== null && (typeof _vm.search.count !== 'undefined') && _vm.search.count !== '') ? _vm.$n(_vm.search.count) : '-')}})]),(_vm.$wait.is(("deleting " + (_vm.search.uuid) + " search item")))?_c('ui-loader',{attrs:{"type":"spinner"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }