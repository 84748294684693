<template>
  <div class="searches-saved-section">
    <ctk-collapse-category-item
      :title="$t('yours_searches_saved')"
      :icon="'folder'"
      data-test="category"
    />

    <div
      v-if="getSavedSearches.length > 0"
      class="searches-container collapse-content tw-flex tw-flex-col"
    >
      <transition-group name="list">
        <search-item
          v-for="search in getSavedSearches"
          :key="search.uuid"
          :search="search"
          @select="$emit('selected', true)"
          @modify-current-search="modifyCurrentSearch"
        />
      </transition-group>
      <ui-loader
        v-if="$wait.is('duplicating search item')"
        type="spinner"
      />
    </div>

    <div
      v-else
      class="tw-py-4 tw-overflow-hidden"
    >
      <div class="searches-saved-section__empty tw-flex tw-items-center tw-p-4 tw-overflow-hidden tw-flex-grow-0">
        <img
          src="@/assets/img/icons/light.svg"
          alt=""
          width="45"
          height="45"
          class="tw-mr-4 tw-hidden md:tw-block tw-select-none"
        >
        <p
          v-text="$t('offers.paragraphs.save_search')"
          class="tw-text-secondary-text tw-mb-0 tw-leading-snug"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkCollapseCategoryItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseCategoryItem/index.vue'
  import SearchItem from './_subs/SearchItem/index.vue'
  import { EventBus } from '@/services/EventBus'

  export default defineComponent({
    name: 'SearchesSavedSection',
    components: {
      CtkCollapseCategoryItem,
      SearchItem
    },
    props: {
      open: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        badge: null,
        refreshInterval: null
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getSavedSearches',
        'getCurrentSearch'
      ])
    },
    created () {
      this.refreshInterval = setInterval(() => {
        this.refreshSearchesCount()
      }, 60000)
    },
    beforeDestroy () {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
      }
    },
    methods: {
      ...mapActions('offers', [
        'resetCurrentOffer', 'resetCurrentProposal', 'setCurrentSearch', 'getOffers', 'refreshSearchesCount'
      ]),
      selectItem (search) {
        if (search.uuid !== this.getCurrentSearch.uuid) this.setASearch(search)
        this.$emit('selected', true)
      },
      setASearch (search) {
        this.setCurrentSearch({ search: search })
        this.resetCurrentOffer()
        if (this.$route.name === 'Proposals') {
          this.resetCurrentProposal()
          this.$store.commit('offers/SET_PROPOSALS', [])
          EventBus.$emit('reset-current-proposal-state')
        }
      },
      modifyCurrentSearch () {
        this.$emit('modify-current-search')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .searches-saved-section {
    &__empty {
      background-color: $light-gray;
      width: 298px;
    }

    .searches-container {
      padding-left: 16px;

      .ui-loader {
        margin: auto;
      }
    }
  }

</style>
