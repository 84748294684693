import { onMounted, ref } from '@vue/composition-api'

/**
 * @param {number} timeoutInMs
 * @returns {{isAfterTimeout: Ref<UnwrapRef<boolean>>, resetTimeout: Ref<UnwrapRef<void>>}}
 */
export default function useAfterTimeout (timeoutInMs) {
  const isAfterTimeout = ref(false)

  const resetTimeout = () => {
    isAfterTimeout.value = false
    triggerTimeout()
  }

  function triggerTimeout () {
    setTimeout(() => {
      isAfterTimeout.value = true
    }, timeoutInMs)
  }

  onMounted(() => {
    triggerTimeout()
  })

  return {
    isAfterTimeout,
    resetTimeout
  }
}
